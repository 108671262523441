import { defineStore } from "pinia";


export const CONTEXT = "context";

export const useContextStore = defineStore(CONTEXT, {
    state: () => ({
        _content: null,
    }),
    actions: {
        setContent(value:any) {
            this._content = value;
        }
    },
    getters: {
        content: (state) =>  state._content,
    },
});